<template>
  <the-wrapper :isSpinner="isSpinner">
    <slot>
      <ServicesDescription
        @connectService="sendRequestOnOff"
        :isValid="valid"
        :isConnected="isConnected"
        :isSubtitle="isSubtitle"
      >
        <template #title>
          {{ layoutData.OptionName }}
        </template>
        <template #subtitle>
          {{ layoutData.Description }}
        </template>
        <template #body>
          <b-row class="lg-align-start align-center">
            <b-col md="11" lg="11" xl="8">
              <b-form id="option-request">
                <div v-for="(item, index) in layoutData.Inputs" :key="index">
                  <b-form-group
                    label-text-align="center"
                    v-if="item.Tag === 'input' && item.Type === 'text'"
                    :label="item.Label"
                    label-cols-sm="5"
                    label-align="left"
                    label-align-sm="left"
                  >
                    <b-input
                      :type="item.Type"
                      v-model="inputValue[index].value"
                      trim
                      required
                    ></b-input>
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.Tag === 'input' && item.Type === 'file'"
                    :label="item.Label"
                    label-cols-sm="5"
                    label-align="left"
                    label-align-sm="left"
                  >
                    <b-form-file
                      ref="file"
                      v-model="inputValue.file"
                      placeholder="Выберите файл или перетащите в поле..."
                      trim
                      required
                    ></b-form-file>
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.Tag === 'select'"
                    :label="item.Label"
                    label-cols-sm="5"
                    label-align="left"
                    label-align-sm="left"
                  >
                    <b-form-select
                      v-model="item.Options[0].Value"
                      :options="inputValue[index].options"
                      :selected="item.Options[0].Value"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </b-form>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="11" class="dflex align-center">
              <BaseButton :disabled="!valid" v-if="isConnected" @click="sendRequestUpdate">
                <template #text>
                  Обновить
                </template>
              </BaseButton>
            </b-col>
          </b-row>
        </template>
      </ServicesDescription>
    </slot>
  </the-wrapper>
</template>

<script>
import URL from '@/basUrl';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import {
  getDetailsWithQuery,
  sendCustomerRequest,
  sendCustomerRequestWithFile
} from '@/api/common';
import { catchFunction, processResponse, ShowSuccesToUser } from '@/additional_helpers/helpers';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      isSubtitle: true,
      isEnabled: false,
      id: this.$route.query.id,
      serverMessage: '',
      layoutData: [],
      inputValue: [],
      isSpinner: true
    };
  },

  components: {
    ServicesDescription,
    BaseButton,
    TheWrapper
  },

  created() {
    getDetailsWithQuery(URL.VPBX_OPTIONS, `id=${this.id}`)
      .then((result) => {
        if (!result.data.IsEnabledOnl) {
          this.$router.replace({
            name: '404', params: { pagePart: 'option' }, query: { id: this.$route.query.id }, replace: true
          });
          return;
        }

        this.layoutData = result.data;
        this.createModel();
        this.SHOW_LOADING(false);

        this.inputValue.forEach((el, index) => {
          if (this.layoutData.Values) {
            const includeKey = Object.prototype.hasOwnProperty.call(this.layoutData.Values, el.key);
            if (includeKey) {
              this.inputValue[index].value = this.layoutData.Values[el.key];
            }
          }
        });
      })
      .catch((e) => catchFunction(e));
  },

  computed: {
    // Проверяет заполнены ли все поля
    valid() {
      const value = this.inputValue.map((el) => el.value === '');
      if (value.includes(true)) {
        return false;
      }
      return true;
    },

    // Проверяет подключена ли опция
    isConnected() {
      if (this.layoutData.IsEnabled) {
        return true;
      }
      return false;
    }
  },

  methods: {
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING' }),

    // создает массив, который используется при отправке на сервер
    // и для отображения данных при загрузке
    createModel() {
      this.inputValue = this.layoutData.Inputs.map((el) => {
        if (el.Options) {
          // получает список option для select
          const optionsList = el.Options.map((item) => item.Value);
          // преобразует option для использования в bootstrap
          const optionsArray = optionsList.map((option) => {
            if (option === 'Выберите один из вариантов') {
              // формирует объект готовый для отображения
              return { text: option, value: option, disabled: true };
            }
            return { text: option, value: option };
          });
          return {
            key: el.Name,
            options: optionsArray,
            value: optionsArray[0].text
          };
        }

        if (el.Attributes.value === 'false' || el.Attributes.value === 'true') {
          this.isEnabled = el.value;
          return { key: el.Name, value: el.Attributes.value };
        }

        return { key: el.Name, value: '' };
      });
    },

    // Изменяет статус услуги
    setStatus(value) {
      if (this.inputValue[0].key === 'enabled') {
        this.inputValue[0].value = String(value);
        return;
      }
      this.inputValue.push({ key: 'enabled', value: String(value) });
    },

    createRequest() {
      const requestData = this.inputValue.map((el) => [el.key, el.value]);

      const row = JSON.stringify({
        OptionId: this.layoutData.OptionId,
        Values: Object.fromEntries(requestData)
      });

      // Если в форме не содержится файл
      if (!this.$refs.file) {
        sendCustomerRequest(URL.OPTION_CONNECT, row)
          .then((result) => {
            processResponse(result);
            this.$router.push({
              name: 'VpbxMain'
            });
          })
          .catch((e) => catchFunction(e));
      } else {
        // Если в форме содержится файл для отправки на сервер
        const formData = new FormData();
        const fileField = this.$refs.file;

        formData.append('json', row);
        formData.append('file', fileField);

        sendCustomerRequestWithFile(URL.OPTION_CONNECT, formData)
          .then((result) => {
            ShowSuccesToUser(JSON.parse(result.text).Error);
            this.$router.push({
              name: 'VpbxMain'
            });
          })
          .catch((e) => catchFunction(e));
      }
    },

    sendRequestOnOff() {
      if (this.isConnected) {
        this.setStatus(false);
      } else {
        this.setStatus(true);
      }
      this.createRequest();
    },

    sendRequestUpdate() {
      this.setStatus(true);
      this.createRequest();
    }
  }
};
</script>

<style lang="scss" module></style>
