<template>
  <option-request></option-request>
</template>

<script>
import optionRequest from '../../components/Vpbx/options/OptionRequest.vue';

export default {
  components: {
    optionRequest
  }
};
</script>

<style></style>
